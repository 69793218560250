<template>
  <PdfPreviewTemplate
    :isAvailable="isAvailable"
    :pdfBlob="pdfBlob"
    :errorMessage="errorMessage"
  />
</template>
<script>
import { getImportantMatter } from '@/apis/agency/importantMatters';
import PdfPreviewTemplate from '@/pages/agency/templates/PdfPreviewTemplate';
import { mapActions } from 'vuex';

export default {
  name: 'ImportantMatterPreview',
  components: {
    PdfPreviewTemplate,
  },

  data() {
    return {
      // 有効であるかどうか
      isAvailable: true,
      // PDFファイル
      pdfBlob: null,

      // エラーメッセージ
      errorMessage: this.$t('description.importantMatterNotFound'),
    };
  },
  async mounted() {
    this.setLoading(true);
    // 重要事項等説明書の取得
    const blob = await getImportantMatter(
      this.$route.params['importantMatterId']
    ).catch(() => {
      this.$set(this, 'isAvailable', false);
    });
    this.setLoading(false);
    // 重要事項等説明書が取得できなかった場合、以降の処理を中止する
    if (!blob) return;
    this.$set(this, 'pdfBlob', blob);
  },
  methods: {
    ...mapActions('ui', ['setLoading']),
  },
};
</script>
